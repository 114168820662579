// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';
@import "~bulma/bulma.sass";

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#wrapper {
  flex: 1;
}

main {
  &>.section {
    padding-bottom: 0;
  }
}

footer {
  &.footer {
    padding: 3rem 1.5rem 7rem;
    margin-top: 4rem;
  }
  .subtitle {
    margin-bottom: .6rem;
    &:not(:last-child) {
      margin-bottom: .6rem;
    }
  }
}

.hero-body {
  padding: 0 1.5rem 2rem;
}

a {
  &.navbar-item {
    &:focus {
      background-color: inherit;
    }
    &:hover {
      background-color: inherit;
    }
    &.is-active {
      background-color: inherit;
    }
  }
}

#cookie-consent {
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #5e5e5e;
  color: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(88, 89, 91, 0.1), 0 0px 0 1px rgba(88, 89, 91, 0.02);
  display: block;
  padding: 0.6rem 2rem;
}

//
//form {
//  &#suggest-website {
//    button {
//      &[type=submit]{
//        margin-left: 3rem;
//      }
//    }
//  }
//}
